/**
 * This array controls the async modules
 * "selector" @string the string given to querySelectorAll(); needs to be valid for this fn
 * "moduleName" @string the file name of the module you wish to load without the extension
 * "defaultArgs" @object object of defaultArgs you wish to instantiate with
 *
 * You can also use HTML data attributes that will be converted to an object and passed to module too
 * data-test-attribute="foo" will convert to {testAttribute: 'foo'}
 *
 * JSON objects will be parsed too as long as you print it correctly using something like :
 * htmlspecialchars(json_encode($array, ENT_QUOTES, 'UTF-8')
 */
export const moduleDefinition = [
    { selector: "[data-styled-select]", moduleName: "StyledSelect" },
    { selector: "[data-page-navigation]", moduleName: "PageNavigation" },
    { selector: "[data-responsive-video]", moduleName: "ResponsiveVideo" },
    { selector: "[data-header-video]", moduleName: "HeaderVideo" },
    { selector: "[data-cta-modal]", moduleName: "CTAModal" },
    { selector: ".article-swiper", moduleName: "ArticleSwiper" },
    { selector: ".single-post, .single-insight, .transparent-nav", moduleName: "LogoContrast" },
];
