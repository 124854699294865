/* globals wordpress_globals */
import "../styles/theme.scss";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import merge from "lodash.merge";
import { fetchSVG } from "./lib/SVGSprite";
import convertDataAttributesToObject from "./lib/convertDataAttributesToObejct";
import { moduleDefinition } from "./modules/definition";
import { widgetDefinition } from "./widgets/definition";
import Widget from "./widgets";
/**
 * Async Modules: Modules are defined in ./modules/definitions.ts
 */
const bootstrap = {
    init() {
        this.asyncSVG.init();
        this.widgets.init();
        this.asyncModules.init(moduleDefinition);
    },
    widgets: {
        init: () => {
            (widgetDefinition !== null && widgetDefinition !== void 0 ? widgetDefinition : []).forEach(({ selector, moduleName, defaultArgs }) => {
                var _a;
                ((_a = document.querySelectorAll(selector)) !== null && _a !== void 0 ? _a : []).forEach((el) => {
                    var _a;
                    const formattedArgs = convertDataAttributesToObject((_a = el.dataset) !== null && _a !== void 0 ? _a : {});
                    const args = merge({}, defaultArgs, formattedArgs);
                    return new Widget(el, moduleName, args);
                });
            });
            // window.addEventListener("initForm", (ev) => {
            //   if (ev.detail.element) {
            //     return new Widget(ev.detail.element, ev.detail.widget);
            //   }
            //
            //   return false;
            // });
        },
    },
    asyncModules: {
        init(modules) {
            (modules !== null && modules !== void 0 ? modules : []).forEach(({ selector, moduleName, defaultArgs, }) => {
                var _a;
                ((_a = document.querySelectorAll(selector)) !== null && _a !== void 0 ? _a : []).forEach((el) => {
                    var _a;
                    const formattedArgs = convertDataAttributesToObject((_a = el.dataset) !== null && _a !== void 0 ? _a : {});
                    const args = Object.assign(Object.assign({}, defaultArgs), formattedArgs);
                    return this.load(el, moduleName, args);
                });
            });
        },
        load: (el, moduleName, args) => {
            try {
                return import(`./modules/${moduleName}`).then((mod) => {
                    const Module = mod.default;
                    return new Module(el, args);
                });
            }
            catch (error) {
                const err = error;
                // eslint-disable-next-line no-console
                console.warn(err);
            }
            return false;
        },
    },
    asyncSVG: {
        init() {
            fetchSVG("./wp-content/themes/class/dist/sprite.svg")
                .catch((error) => console.warn(`[fetchSVG] ${error}`));
        },
    },
};
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
