var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "@projAssets/icons/example.svg";
import "@projAssets/icons/filter.svg";
import "@projAssets/icons/sort.svg";
import "@projAssets/icons/close.svg";
import "@projAssets/icons/burger.svg";
import "@projAssets/icons/refresh.svg";
import "@projAssets/icons/chevron.svg";
import "@projAssets/icons/chevron-left.svg";
import "@projAssets/icons/progress-fish.svg";
import "@projAssets/icons/progress-fisherman.svg";
import "@projAssets/icons/progress-finished.svg";
import "@projAssets/icons/progress-floor.svg";
import "@projAssets/icons/social-facebook.svg";
import "@projAssets/icons/social-twitter.svg";
import "@projAssets/icons/social-instagram.svg";
import "@projAssets/icons/social-linkedin.svg";
import "@projAssets/icons/speaker-on.svg";
import "@projAssets/icons/speaker-off.svg";
function getBaseURL() {
    return `${window.location.protocol}//${window.location.hostname}${(window.location.port ? `:${window.location.port}` : "")}`;
}
function domReady(callback) {
    if (document.readyState === "complete" || document.readyState !== "loading") {
        callback();
    }
    else {
        document.addEventListener("DOMContentLoaded", callback);
    }
}
export function fetchSVG(filename) {
    const baseUrl = getBaseURL();
    let url = null;
    filename ? (url = filename) : null;
    if (!url) {
        return Promise.reject(new Error("Invalid URL"));
    }
    url = (`${baseUrl}/${url}`).replace(/([^:]\/)\/+/g, "$1");
    return fetch(url, {})
        .then((resp) => __awaiter(this, void 0, void 0, function* () {
        if (resp.status < 200 || resp.status >= 300) {
            return Promise.reject(new Error(`Invalid HTTP Code - HTTP code: ${resp.status} (${resp.statusText})`));
        }
        const responseText = yield resp.text();
        if (!responseText || responseText.slice(0, 4) !== "<svg") {
            return Promise.reject(new Error("Invalid SVG Response"));
        }
        const div = document.createElement("div");
        div.innerHTML = responseText;
        div.setAttribute("id", "svg-icon-sprite");
        domReady(() => {
            document.body.insertAdjacentElement("beforeend", div);
        });
        return Promise.resolve(true);
    }));
}
