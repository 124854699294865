import { jsx as _jsx } from "preact/jsx-runtime";
import { render } from "preact";
class Widget {
    constructor(element, module, args = {}) {
        this.element = element;
        this.widgetName = module;
        this.args = args;
        this.init();
    }
    init() {
        import((`@projWidgets/${this.widgetName}/index.ts`)).then(({ default: WidgetComponent }) => {
            render((_jsx(WidgetComponent, Object.assign({}, this.args))), this.element);
        });
    }
}
export default Widget;
